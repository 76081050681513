/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/scss/bootstrap";
@import url("styles/fonts.scss");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;900&display=swap");
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  font-family: "Circular Std", sans-serif;
}
app-layout {
  display: flex;
  min-height: 100%;
}

app-cook-admin,
app-driver-admin,
app-customer-admin,
app-brand-admin {
  width: 100%;
}

.required:after {
  content: " *";
  color: #e84b4b;
}
.required-before:before {
  content: " *";
  color: #e84b4b;
}

.cursor-pointer {
  cursor: pointer;
}

.dropdown-menu {
  overflow: auto;
  max-height: 350px;
  padding-bottom: 0;
}

.hot-toast {
  min-width: 250px !important;
  margin-top: 20% !important;
  .hot-toast-icon {
    align-self: unset !important;
    padding: unset !important;
    margin-left: 16px;
    .hot-toast-loader-icon {
      width: 24px;
      height: 24px;
    }
  }
  .hot-toast-message {
    font-size: 20px;
  }
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  display: none !important;
}
.mat-form-field-underline {
  display: none;
}

.mat-form-field {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 0;
}

ngb-modal-window {
  .modal-dialog {
    max-width: 1001px;
    border: 1px solid #6775ab;
    box-sizing: border-box;
    box-shadow: 0px 4px 9px rgb(0 0 0 / 25%);
    border-radius: 10px;
  }
}

.custom-modal-class .modal-dialog {
  max-width: 600px;
}

app-driver-admin {
  width: 100%;
}

.confirmation-modal,
.generic-modal {
  width: 600px;
}

.timepicker-overlay,
.timepicker-backdrop-overlay {
  z-index: 2000 !important;
}
.cdk-overlay-container {
  z-index: 2000;
}

.gm-style div[aria-hidden="true"] {
  margin-top: 15px;
  background-color: white !important;
  padding: 2px;
  border-radius: 5px;
}

.modal-backdrop {
  width: 100%;
  height: 100%;
}

.table > :not(caption) > * > * {
  color: #fff !important;
  background-color: transparent !important;
}

.ng-select.custom .ng-select-container {
  height: 45px;
}

.c-btn.disabled {
  background-color: #fffbf0 !important;
}

/** styles common to all order history pages **/
.orders-history {
  table {
    border-collapse: separate;
    border-spacing: 0 10px;
    width: 100%;

    td,
    th {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.02em;
      border: none;
      padding: 5px 8px;
      text-align: center;
    }

    td:not(:last-child):not(:first-child),
    th:not(:last-child):not(:first-child) {
      padding: 5px 10px 5px 0;
    }

    thead {
      background-color: #32458e;
      height: 50px;
      font-weight: 700;
      color: #ffffff;

      & td:first-child {
        border-top-left-radius: 8px;
      }

      & td:last-child {
        border-top-right-radius: 8px;
      }
    }

    tbody {
      font-weight: 400;
      color: black;
      border: 1px solid #e5e5e5;
      tr {
        background-color: #f6f6f6;
        &.disabled {
          opacity: 0.8;
          cursor: none;
          pointer-events: none;
        }
        &:not(.no-hover):hover {
          cursor: pointer;
          background-color: #e6e6e6;
        }

        td {
          height: 50px;
          border-top: 1px solid #e5e5e5;
          border-bottom: 1px solid #e5e5e5;

          & span.text-ellipsis {
            max-width: 95px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .option {
            cursor: pointer;
            font-weight: 900;
            cursor: pointer;
            font-size: 20px;
            border: unset;
            background: unset;
          }
          .option ~ .dropdown-menu {
            z-index: 8;
            width: 119px;
            background: #ffffff;
            border: 0.5px solid #979797;
            box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
            padding: 6.8px 9px;
            border-radius: 8px;
            li {
              border-bottom: 0.5px solid #c4c4c4;
              &.disabled {
                opacity: 0.4;
                cursor: none;
                pointer-events: none;
              }
              a {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 2.5px 0;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                text-align: center;
                letter-spacing: -0.02em;
                color: #414042;
              }
              a:hover {
                background: #e9edf7;
                border-radius: 4px;
              }
            }
            li:last-of-type {
              border-bottom: unset;
            }
          }
        }

        & td:first-child {
          border-radius: 8px 0 0 8px;
          border-left: 1px solid #e5e5e5;
        }

        & td:last-child {
          border-radius: 0 8px 8px 0;
          border-right: 1px solid #e5e5e5;
        }

        & td:first-child:last-child {
          border-radius: 8px;
          border-left: 1px solid #e5e5e5;
          border-right: 1px solid #e5e5e5;
        }
      }

      tr.parent-order {
        background-color: #e6e6e6;
        & td:first-child {
          border-left: 1px solid #e5e5e5;
          border-radius: 8px 0px 0px 0px;
        }
        & td:last-child {
          border-right: 1px solid #e5e5e5;
          border-radius: 0px 8px 0px 0px;
        }
      }

      tr.related-order {
        margin-bottom: -4px;
        &:hover td {
          background-color: #d1e3fa;
        }
        & td {
          margin-top: -4px;
          height: 40px;
          font-size: 12px;
          background-color: #e4effc;
          border-radius: 0px;
        }
        td:first-child {
          border-left: 1px solid #e5e5e5;
        }
        td:last-child {
          border-right: 1px solid #e5e5e5;
        }

        &:not(:has(~ .related-order)) {
          background-color: #e4effc;
          td:first-child {
            border-left: 1px solid #e5e5e5;
            border-radius: 0px 0px 0px 8px;
          }

          td:last-child {
            border-right: 1px solid #e5e5e5;
            border-radius: 0px 0px 8px 0px;
          }
        }

        span.time-slot,
        span.mealtime {
          font-size: 12px;
        }

        span.status {
          font-size: 10px;
          padding: 0 8px;
        }

        .pre-order,
        .made-to,
        .ready-made,
        .instant,
        .instant-order,
        .order-channel,
        .review-updated {
          font-size: 6px;
          font-weight: normal;
        }
      }
      tr.related-order-header {
        background: #737a99;
        color: #ffffff;
        &:hover td {
          background-color: #737a99;
        }
        & td {
          margin-top: -4px;
          height: 40px;
          font-size: 12px;
        }
      }
    }
  }

  span.status {
    display: inline-block;
    font-size: 12px;
    text-wrap: nowrap;
    color: #ffffff;
    background: #bfbfbf;
    border-radius: 4px;
    padding: 4px 8px;

    &.orderCompleted {
      background: #2d8c00;
    }
    &.orderConfirmed {
      background: #2d8d00;
    }
    &.cookAcknowledged {
      background: #ffdb99;
      color: #000 !important;
    }
    &.orderPreparing {
      background: #8e6700;
    }
    &.deAssigned,
    &.refundProcessed {
      background: #00388c;
    }
    &.deAssigning {
      background: #2e008d;
    }
    &.readyForPickUp,
    &.refundInitiated {
      background: #818c00;
    }
    &.deReached {
      background: #2d8d00;
    }
    &.orderInComplete {
      background: #8a4200;
    }
    &.partiallyFulfilled {
      background: #8a4200;
    }
    &.delivered {
      background: #e4b61a;
    }
    &.orderPlaced {
      background: #008d6b;
    }
    &.firstMileReached {
      background: #008c5a;
    }
    &.orderPickedUp {
      background: #007a8a;
    }
    &.lastMileReached {
      background: #00488b;
    }
    &.orderDelivered {
      background: #2d8c00;
    }

    &.orderConfirmed {
      background: #008d6b;
    }
    &.chefOrderCreated {
      background: #b3d9ff;
      color: black;
    }
    &.orderUndelivered {
      background: #8a4200;
    }
    &.orderProcessing {
      background: #b5edff;
      color: black;
    }
    &.partiallyShipped {
      background: #8071e3;
    }
    &.partiallyDelivered {
      background: #bdf5be;
      color: #000;
    }
    &.orderCancelled,
    &.orderFailed,
    &.refundFailed {
      background: #8e0000;
    }
    &.noRiderAvailable {
      background-color: #e84b4b3b;
      color: #b61e1e;
    }
  }

  span.time-slot {
    border-radius: 4px;
    padding: 0px 5px;
    display: inline-block;

    &.red {
      background-color: #ff9999;
    }

    &.orange {
      background-color: #ffaf7a;
    }

    &.yellow {
      background-color: #fefebe;
    }
  }

  span.mealtime {
    background: #e4b61a;
    color: #000;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
    text-transform: capitalize;
  }

  .tags-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    left: 0px;
    bottom: 0px;
    font-weight: bold;
    font-size: 7px;
    z-index: 1;

    span:not(:first-child) {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }

    span:first-child {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }

    span:not(:last-child) {
      margin-right: 2px;
    }

    .high-value-order,
    .pre-order,
    .made-to,
    .ready-made,
    .instant,
    .instant-order,
    .order-channel,
    .review-updated {
      padding: 2px 10px;
      text-wrap: nowrap;
      line-height: normal;
      letter-spacing: 1px;
      z-index: 1;
    }

    .high-value-order {
      background-color: #9a45b6;
      color: #fff;
    }
    .pre-order {
      background-color: #facd09;
      color: #000;
    }
    .made-to {
      background-color: #9c6bf3;
      color: #fff;
    }
    .ready-made {
      background-color: #dc21ff;
      color: #fff;
    }
    .instant {
      background-color: #27c24c;
      color: #fff;
    }
    .instant-order {
      background-color: #ff7f0a;
      color: #fff;
    }
    .order-channel {
      background-color: #09990f;
      color: #fff;
    }
    .review-updated {
      background-color: #09990f;
      color: #fff;
    }
  }
  .coupon-initiated {
    padding: 2px 10px;
    text-wrap: nowrap;
    line-height: normal;
    letter-spacing: 1px;
    z-index: 1;
    background-color: #0047b3;
    color: #fff;
    font-size: 6px;
    font-weight: normal;
    bottom: 0;
    left: 0;
    border-radius: 8px 8px 0 0;
  }
}

/** styles iti international number **/
.iti {
  display: block !important;
}

.iti__flag-container {
  position: absolute;
}

.iti__selected-flag {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
}

.custom-tel-input {
  height: 35px;
  border-radius: 2px;
  margin-bottom: 0;
  padding-left: 85px !important;
}
.custom-tel-input::placeholder {
  color: #c7cace;
}

.iti .dropdown-menu.country-dropdown {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-color: #c7cace;
}

.iti .iti__country-list {
  box-shadow: none;
  font-size: 14px;
  margin-left: 0;
  width: 198px;
  max-height: 170px;
}

.iti__flag-container.open + input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

ngx-intl-tel-input input {
  padding: 10px;
  border: 1px solid #c7cace;
  font-size: 1rem;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 1px solid #f00;
  box-shadow: 0px 0px 5px #f00;
}

ngx-intl-tel-input input:focus {
  outline: none !important;
  border-color: #000;
}

ngx-intl-tel-input input::placeholder,
ngx-intl-tel-input input::-webkit-input-placeholder,
ngx-intl-tel-input input:-ms-input-placeholder,
ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}
